import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import companyThumbnail from "../images/oliver_pers.jpg"
import PageHeader from "../components/pageHeader"
import AboutCard from "../components/aboutCard"

import "./about.scss"


const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query AboutdataQuery {
      site {
        siteMetadata {
          title
          fullTitle
        }
      }
    }
  `)

  const { title, fullTitle } = data.site.siteMetadata

  const aboutContent = [
    `Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea molestias incidunt reprehenderit. Aliquam harum laboriosam reprehenderit delectus nostrum iste in ullam perspiciatis! Doloremque quibusdam quo quas deleniti. Libero tempore nobis autem pariatur, cumque sunt dolor consequuntur sit velit! Voluptates ea sequi vitae distinctio voluptatem. Quis, tenetur necessitatibus? Dicta, perferendis provident?`,
    `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem nisi esse dolorum architecto, id et illo, mollitia consectetur eaque debitis enim ea facere provident aperiam repellat nam natus molestiae expedita?`,
    ]

    const aboutContent2 = [
      `2 beschreibung ncidunt reprehenderit. Aliquam harum laboriosam reprehenderit delectus nostrum iste in ullam perspiciatis! Doloremque quibusdam quo quas deleniti. Libero tempore nobis autem pariatur, cumque sunt dolor consequuntur sit velit! Voluptates ea sequi vitae distinctio voluptatem. Quis, tenetur necessitatibus? Dicta, perferendis provident?`,
      `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem nisi esse dolorum architecto, id et illo, mollitia consectetur eaque debitis enim ea facere provident aperiam repellat nam natus molestiae expedita?`,
      ]
  return (
    <section id="about">
      <PageHeader title="Über uns" />
      <Container>
        {/* <Row>

          <Col className="lg-6 md-6 sm-12">
          </Col>
          <Col className="lg-6 md-6 sm-12">
          </Col>

        </Row> */}
        <main className="about-page section-about">
          {/* <h2 className="header-title text-center font-weight-bold">{title}</h2> */}
          <section className="about-content">
            <img
              src={companyThumbnail}
              alt="Garten und Kunst in Winterthur "
              className="img-thumbnail w-50 mr-3"
              align="left"
            />
            <h2 className="content-title font-weight-bold">Oliver</h2>
            {aboutContent.map(para => (
              <p>{para}</p>
            ))}
          </section>
          </main>
          <hr />

          <main className="section-about-down">


          <section className="about-content">
            
           
            
            <img
              src={companyThumbnail}
              alt="Gärtner fürGartenbau und Gartenpflege"
              className="img-thumbnail w-50 mr-3"
              align="left"
            />
             <h2 className="content-title font-weight-bold">Person 2</h2>
            {aboutContent2.map(para => (
              <p>{para}</p>
            ))}
          </section>
         
          </main>

          {/* <Row>
            <Col className="md-4 lg-4">
              <AboutCard title="Mission">
                <p>
                  <strong>{fullTitle} </strong>Ipsum dolor sit amet consectetur
                  adipisicing elit. Ullam quisquam ratione et sint, quos
                  dolores, corporis debitis minus, vitae officia adipisci ipsa
                  fugiat est dolorum eum optio blanditiis quas doloribus hic
                  saepe dicta fugit exercitationem. Sed, recusandae atque nobis
                  facilis voluptates, dolores quaerat aspernatur dolorem iusto
                  autem, facere ipsa. Fugiat
                </p>
                <ol>
                  <li>Alles aus einer Hand</li>
                  <li>
                    Der Service aus Zürich
                  </li>
                
                </ol>
              </AboutCard>
            </Col>
            <Col md="12">
              <AboutCard title="Vision">
                <p>
                  Ipsum dolor sit amet consectetur adipisicing elit. Ullam
                  quisquam ratione et sint, quos dolores, corporis debitis
                  minus, vitae officia adipisci ipsa fugiat est dolorum eum
                  optio blanditiis quas doloribus hic saepe dicta fugit
                  exercitationem. Sed, recusandae atque nobis facilis
                  voluptates, <strong>{fullTitle} </strong>
                  dolores quaerat aspernatur dolorem iusto autem, facere ipsa.
                </p>
              </AboutCard>
              <AboutCard title="Werte">
                <p>
                  Ipsum dolor sit amet consectetur adipisicing elit. Ullam
                  quisquam ratione et sint, quos dolores, corporis debitis
                  minus, vitae officia adipisci ipsa fugiat est{" "}
                  <strong>{title} </strong>dolorum eum optio blanditiis quas
                  doloribus hic saepe dicta fugit exercitationem.
                </p>
                <ul>
                  <li>Pro</li>
                  <li>Flexibel</li>
                  <li>Integriert</li>
                </ul>
              </AboutCard>
            </Col>
          </Row>
          <hr /> */}
      
      </Container>
    </section>
  )
}

export default AboutPage
